import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "./VideoPlayer.css";


const ShowList = (video) => {
   const location = useLocation();
   const [movieId, setMovieId] = useState(null);  // Initialize as null
   const [movieData, setMovieData] = useState([]);
 
   // Extract movieId from URL
   useEffect(() => {
     const pathParts = location.pathname.split("/");
     const movieNum = pathParts[pathParts.length - 1];
     console.log("variable: " + movieNum);
     setMovieId(movieNum);
     console.log("movie Id state: " + movieId);
   }, [location]);
 
   // Fetch movie data when movieId is set
   useEffect(() => {
     if (movieId) {
       fetch(`https://dashboard.ucqire.com/api/by-id-movie?id=${movieId}`)
         .then((response) => response.json())
         .then((data) => setMovieData(data))
         .catch((error) =>
           console.error("Error fetching data for first slider:", error)
         );
     }
   }, [movieId]);  // Add movieId as a dependency
 
   console.log("movie Id state: " + movieId);
   console.log(movieData.url)

  return (
    <>
      {/* <div className="video-container iq-main-slider"> */}
      {/* <ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' /> */}
      <video className="video d-block" controls loop>
        <source src={video} type="video/mp4" />
      </video>
    </>
  );
};
export default ShowList;
